<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <b-form @submit.prevent="loginWithOTP">
          <div class="row">
            <div class="col-12 pt-5">
              <div class="text-center">
                <b-img :src="sypitarn_mascot" class="mx-auto" style="max-width: 200px" alt="Mascot"></b-img>
              </div>
              <h5 class="text-center mt-4">{{ $t('welcome_to_synpitarn') }}</h5>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('phone_number.label') }}
                  <span v-if="!$v.phone.required || !$v.phone.minLength || !$v.phone.maxLength" class="text-danger"
                    >*</span
                  >
                </h6>
              </div>
              <b-form-input
                v-model.trim="$v.phone.$model"
                v-mask="'##########'"
                size="sm"
                :class="{ error: $v.phone.$error }"
                :placeholder="$t('phone_number.placeholder')"
                autocomplete="off"
                type="tel"
              ></b-form-input>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('pincode.label') }}
                  <span
                    v-if="!$v.pincode.required || !$v.pincode.minLength || !$v.pincode.maxLength"
                    class="text-danger"
                    >*</span
                  >
                </h6>
              </div>

              <div class="position-relative">
                <b-form-input
                  v-model.trim="$v.pincode.$model"
                  v-mask="'######'"
                  :type="showPassword ? 'text' : 'password'"
                  size="sm"
                  :class="{ error: $v.pincode.$error }"
                  :placeholder="$t('pincode.placeholder')"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  autocomplete="off"
                ></b-form-input>
                <i v-if="showPassword" class="append-icon ri-eye-line ri-lg" @click="showPassword = false"></i>
                <i v-else class="append-icon ri-eye-off-line ri-lg" @click="showPassword = true"></i>
              </div>
            </div>

            <div class="col-12 mt-4 pb-4 text-right text-primary">
              <router-link to="/forget-password">
                <h6 class="font-weight-bold">{{ $t('forget_pincode') }}</h6>
              </router-link>
            </div>

            <div class="col-12 my-4 text-center">
              <b-button type="submit" pill variant="primary px-4">{{ $t('continue') }}</b-button>
            </div>
          </div>
        </b-form>

        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from '@/api/service/AccountService'
import NotificationService from '@/api/service/NotificationService'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from '@/components/Loading.vue'
import Cookies from 'js-cookie'

export default {
  components: { Loading },
  data() {
    return {
      phone: null,
      pincode: null,
      sypitarn_mascot: require('@/assets/images/sypitarn_mascot.png'),
      isBusy: false,
      showPassword: false,
      onesignalUserId: null,
    }
  },
  methods: {
    async loginWithOTP() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          const res = await AccountService.login({
            phone_number: this.phone,
            type: 'pincode', // pincode | otpcode
            code: this.pincode,
          })

          this.isBusy = false

          if (res.status == 200) {
            const loginResponse = res.data.data
            const loanSubmitted = loginResponse.loan_application_submitted
            const loanFormState = loginResponse.loan_form_state

            this.$store.dispatch('auth/setUserId', {
              userId: loginResponse.id,
            })
            this.$store.dispatch('auth/setToken', {
              token: loginResponse.token,
            })
            this.$store.dispatch('auth/setLoanSubmitted', {
              loanSubmitted: loanSubmitted,
            })
            this.$store.dispatch('auth/setLoanFormState', {
              loanFormState: loanFormState,
            })
            this.$store.dispatch('auth/setActive', {
              active: loginResponse.active,
            })

            this.$store.dispatch('auth/setRejectCode', {
              rejectCode: loginResponse.reject_code,
            })

            this.storeOneSignalUserId()
            this.changeLanguage()

            if (loanSubmitted) {
              this.$router.replace({ name: 'home' })
            } else {
              if (loanFormState === null) {
                this.$router.replace({ path: '/personal-detail' })
              } else if (loanFormState === 'personal') {
                this.$router.replace({ path: '/employment' })
              } else if (loanFormState === 'employment') {
                this.$router.replace({ path: '/financial' })
              } else if (loanFormState === 'financial') {
                this.$router.replace({ path: '/file-upload' })
              } else if (loanFormState === 'documents') {
                this.$router.replace({ path: '/interview-appointment' })
              }
            }
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
    async storeOneSignalUserId() {
      this.onesignalUserId = Cookies.get('onesignal_user_id')
      if (this.onesignalUserId) {
        const res = await NotificationService.storeUserId({
          one_signal_user_id: this.onesignalUserId,
        })
        console.log(res)
      }
    },
    async changeLanguage() {
      try {
        await AccountService.setLanguage()
      } catch (err) {
        console.log(err)
      }
    },
    redirect() {
      const intendedUrl = Cookies.get('intended_url')
      if (intendedUrl) {
        Cookies.remove('intended_url')
        this.$router.push({ path: intendedUrl })
      } else {
        this.$router.push({ name: 'home' })
      }
    },
  },
  validations: {
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    pincode: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
}
</script>

<style></style>
